ResetPassword
<template>
  <div class="join">
    <div class="baseBox">
      <div class="joinBox errorpage">
        <h1><i class="fas fa-exclamation-triangle"></i>Error 500</h1>
        <h3>죄송합니다. 현재 시스템 응답이 원활하지 않습니다.<br />오류가 지속적으로 발생할 경우 관리자에서 문의해주시기 바랍니다.</h3>
        <div class="buttonWrap">
          <router-link to="/home">
            <button class="large point">홈으로 이동</button>
          </router-link>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "@/components/Footer";

export default {
  components: { Footer },
};
</script>
